<template>
    <div
        :class="{
            'codesamples-pr': activeDocHasCode && !isLoadingDoc,
            'codesamples--open': codeSamplesOpen,
        }">
        <div class="flex flex-row justify-center">
            <div
                class="docs-content w-full py-10 px-6"
                :class="{
                    'lg:w-2/3': !(activeDocHasCode && !isLoadingDoc),
                    'md:w-full': activeDocHasCode && !isLoadingDoc,
                }">
                <DocsLoader v-if="isLoadingDoc" />
                <div
                    :ref="'docsContent'">
                    <VueMarkdown
                        v-if="!isLoadingDoc"
                        :source="activeDoc.content"
                        :anchorAttributes="{target: '_blank'}"
                        :linkify="false"
                        :prerender="parseContent"
                        @rendered="onMarkdownRendered" />
                </div>
                <TutorialExplore
                    class="mt-16"
                    v-if="!isLoadingDoc"
                    :product="this.activedocProduct"
                    :hasCode="this.activeDocHasCode"
                />
            </div>
        </div>
        <span
            v-if="activeDocHasCode && !isLoadingDoc"
            class="codesamples_trigger bg-codesample shadow-md py-4 px-2 top-24 right-0 md:hidden rounded-l-md flex items-center content-center"
            @click="openCodeSamples">
            <codeSvg class="h-4 fill-current text-gray-100" />
        </span>
        <div
            v-if="activeDocHasCode && !isLoadingDoc"
            ref="codeSamples"
            class="fixed codesamples codesamples-w bottom-0 right-0 top-0 md:top-16 lg:z-60 bg-codesample shadow-2xl md:shadow-none pointer-events-none md:pointer-events-auto opacity-0 md:opacity-100 transform translate-x-full md:translate-x-0">
            <DocsCodeSamples
                :codeSamples="activeDoc ? activeDoc.codeSamples || [] : []"
                :responseSamples="activeDoc ? activeDoc.responseSamples || [] : []" />
        </div>
        <div
            class="block md:hidden codesamples__backpage opacity-0 fixed bg-white pointer-events-none top-0 bottom-0 w-screen z-50"
            ref="codeSamplesBackpage"
            @click="closeCodeSamples"></div>
    </div>
</template>

<script>
import gsap from 'gsap';

import {
    mapActions,
    mapMutations,
    mapState,
} from 'vuex';

import VueMarkdown from 'vue-markdown';

import hljs from 'highlight.js/lib/core';

import cpp from 'highlight.js/lib/languages/cpp';
import bash from 'highlight.js/lib/languages/bash';
import json from 'highlight.js/lib/languages/json';
import php from 'highlight.js/lib/languages/php';
import plaintext from 'highlight.js/lib/languages/plaintext';
import xml from 'highlight.js/lib/languages/xml';

import transitions from '@/components/mixins/transitions';
import DocsLoader from '@/components/docs/DocsLoader.vue';

hljs.registerLanguage('c++', cpp);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('c', cpp);
hljs.registerLanguage('plaintext', plaintext);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('json', json);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('php', php);

// FIXME::ckarundu Explore escaping html instead
hljs.configure({ ignoreUnescapedHTML: true });

export default {
    name: 'Docs',

    mixins: [transitions],

    props: {
        fileName: {
            type: String,
            required: true,
        },
    },

    data() {
        const isMobile = window.innerWidth < 800;
        const codeSamplesOpen = !isMobile;

        return {
            codeSamplesOpen,
        };
    },

    computed: {
        ...mapState('docs', [
            'activeDocsFile',
            'isLoadingDoc',
        ]),

        activeDoc() {
            return this.extractDocEntities(this.activeDocsFile.content);
        },

        activeDocHasCode() {
            if (this.activeDoc) {
                return this.activeDoc.codeSamples && this.activeDoc.codeSamples.length >= 1;
            }
            return false;
        },

        activedocProduct() {
            return this.fileName.split('/')[0];
        },
    },

    watch: {
        fileName(newFileName) {
            this.loadDocsFile(newFileName);
        },
    },

    created() {
        this.loadDocsFile(this.fileName);
    },

    mounted() {
        this.$nextTick(() => {
            this.registerDocLinksHandler();
        });
    },

    beforeDestroy() {
        this.removeDocLinksHandler();
        this.setActiveDocsFile({});
    },

    methods: {
        ...mapActions('docs', [
            'getDocsFile',
        ]),

        ...mapMutations('docs', [
            'setActiveDocsFile',
        ]),

        async loadDocsFile(fileName) {
            try {
                const fileNameWithMimeType = `${fileName}.md`;
                await this.getDocsFile({ fileName: fileNameWithMimeType });
            } catch (ex) {
                if (/not found/ig.test(ex)) {
                    this.$router.push({
                        path: '/404',
                    });
                }
            }
        },

        parseContent(content = '') {
            const parsers = [
                {
                    matcher: /^>\s(.*)::(.*?)\n((?:>.*\n)+)$/gm,
                    replacer: '<div class="callout $1"><span class="title">$2</span><span class="text">$3</span></div>\n',
                },
                {
                    matcher: /!\[alt text\]\((.*) "(.*)"\)/gm,
                    replacer: '<div class="doc__image"><img src="$1" alt="$2"/></div>\n',
                },
                {
                    matcher: /\[(.*?)\]\(\{\$\{baseDomain\}\/docs\/(.*?)\}\)/gm,
                    replacer: '<a href="/docs/$2">$1</a>',
                },
            ];

            const parsedContent = parsers
                .reduce((baseString, parser) => {
                    const parsedString = baseString
                        .replace(
                            parser.matcher,
                            parser.replacer,
                        );
                    return parsedString;
                }, content);

            return parsedContent;
        },

        calloutTextFormat() {
            const elements = this.$refs.docsContent.getElementsByClassName('callout');
            const callOuts = Array.from(elements);
            const splitText = (text = '') => {
                const textArray = text.split(/>\s+/);
                const [firstElement] = textArray;
                if (firstElement === '') {
                    textArray.splice(0, 1);
                }
                return textArray;
            };

            callOuts.forEach((callout) => {
                for (let i = 0; i < callout.childNodes.length; i += 1) {
                    if (callout.childNodes[i].className === 'text') {
                        const node = callout.childNodes[i];
                        const contentArray = splitText(node.textContent);
                        contentArray.forEach((content) => {
                            const span = window.document.createElement('span');
                            span.textContent = content;
                            span.className = 'callout__text';
                            callout.appendChild(span);
                        });
                        callout.removeChild(node);
                    }
                }
            });
        },

        highlightCodeSamples() {
            this.$refs.docsContent.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block);
            });
        },

        onMarkdownRendered() {
            this.$nextTick(() => {
                this.calloutTextFormat();
                this.highlightCodeSamples();
            });
        },

        extractDocEntities(content = '') {
            if (!content) {
                return { content };
            }

            const codeSampleTest = /\{code_sample_start(::(.*)::(.*))?\}([\s\S]+)\{code_sample_end\}/gm;
            const responseSampleTest = /\{response_sample_start\}([\s\S]+)\{response_sample_end\}/gm;

            const codeSampleMatches = content.match(codeSampleTest);
            const responseSampleMatches = content.match(responseSampleTest);

            const docsContent = [codeSampleTest, responseSampleTest].reduce((base, matcher) => {
                const replacedContent = base.replace(matcher, '');
                return replacedContent;
            }, content);

            let codeSamples = [];
            if (codeSampleMatches !== null) {
                const matchedSampleContent = codeSampleMatches[0];

                // const courseReferenceMatches = matchedSampleContent.match(/\{code_sample_start::(.*)::(.*)\}/gm);

                // if (courseReferenceMatches !== null) {
                //     const courseReferenceParts = courseReferenceMatches[0].split('::');
                //     /* eslint-disable-next-line */
                //     this.codeSamplesCourseId = courseReferenceParts[1];
                //     this.codeSamplesLessonPosition = parseInt(courseReferenceParts[2], 10);
                // }

                const languageParsers = [{
                    runtime: 'curl',
                    matcher: /```\bbash\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'php',
                    matcher: /```\bphp\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'java',
                    matcher: /```\bjava\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'node',
                    matcher: /```\bjavascript\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'python',
                    matcher: /```\bpython\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'ruby',
                    matcher: /```\bruby\b([\s\S]+?)```/gm,
                }, {
                    runtime: 'c',
                    matcher: /```\bc\b([\s\S]+?)```/gm,
                }];

                codeSamples = languageParsers
                    .reduce((base, parser) => {
                        const languageContentMatches = matchedSampleContent.match(parser.matcher);
                        const languageContent = languageContentMatches !== null
                            ? languageContentMatches[0]
                            : '';

                        base.push({
                            runtime: parser.runtime,
                            content: languageContent,
                        });
                        return base;
                    }, [])
                    .filter((cs) => cs.content.length > 0);
            }

            let responseSamples = [];
            if (responseSampleMatches !== null) {
                const matchedSampleContent = responseSampleMatches[0];

                const responseTypeParsers = [{
                    matcher: /```json([\s\S]+?)```/gm,
                }];

                responseSamples = responseTypeParsers
                    .reduce((base, parser) => {
                        const responseTypeContentMatches = matchedSampleContent.match(parser.matcher);
                        const responseTypeContent = responseTypeContentMatches !== null
                            ? responseTypeContentMatches[0]
                            : '';

                        base.push({
                            content: responseTypeContent,
                        });
                        return base;
                    }, [])
                    .filter((cs) => cs.content.length > 0);
            }

            return {
                content: docsContent,
                codeSamples,
                responseSamples,
            };
        },

        docLinksHandler(evt) {
            const node = evt.target;

            if (node.nodeName !== 'A') {
                return true;
            }

            const href = node.getAttribute('href');
            const toLink = href.replace(/\/docs\/(.*)$/gm, '$1');

            if (toLink.includes(':')) {
                return true;
            }

            evt.stopImmediatePropagation();
            evt.preventDefault();
            this.$router.push(`/docs/${toLink}`);

            return false;
        },

        registerDocLinksHandler() {
            const self = this;
            this.$refs.docsContent.addEventListener('click', self.docLinksHandler);
        },

        removeDocLinksHandler() {
            const self = this;
            this.$refs.docsContent.removeEventListener('click', self.docLinksHandler);
        },

        openCodeSamples() {
            if (this.codeSamplesOpen) return;

            const codeSamplesTimeline = gsap.timeline({
                onComplete: () => {
                    this.codeSamplesOpen = true;
                },
            });

            codeSamplesTimeline
                .to(this.$refs.codeSamples, {
                    duration: 0.3,
                    translateX: 0,
                    opacity: 1,
                    ease: 'in',
                })
                .to(this.$refs.codeSamplesBackpage, {
                    duration: 0.2,
                    opacity: 0.7,
                    ease: 'in',
                }, '-=0.2');
        },

        closeCodeSamples({ delay } = { delay: 0 }) {
            if (!this.codeSamplesOpen) return;

            const codeSamplesTimeline = gsap.timeline({
                onComplete: () => {
                    this.codeSamplesOpen = false;
                },
                delay,
            });

            codeSamplesTimeline
                .to(this.$refs.codeSamples, {
                    duration: 0.3,
                    translateX: '105%',
                    opacity: 0.8,
                    ease: 'out',
                })
                .to(this.$refs.codeSamplesBackpage, {
                    duration: 0.2,
                    opacity: 0,
                    ease: 'out',
                }, '-=0.2');
        },
    },

    components: {
        DocsLoader,
        VueMarkdown,
        DocsCodeSamples: () => import('@/components/docs/DocsCodeSamples.vue'),
        TutorialExplore: () => import('@/components/tutorials/TutorialExplore.vue'),
        codeSvg: () => import('@/assets/img/code.svg'),
    },
};
</script>

<style lang="postcss">
:root {
    --codeSampleWidthSm: 20rem;
    --codeSampleWidthMd: 27.875rem;
    --codeSampleWidthLg: 30rem;
    --codeSampleWidthXl: 37.875rem;
}

.codesamples-w {
    @apply w-10/12;
}

.codesamples-pr {
    @apply pr-0;
}

@screen md {
    .codesamples-w {
        width: var(--codeSampleWidthMd);
    }

    .codesamples-pr {
        padding-right: var(--codeSampleWidthMd);
    }
}

@media (orientation: portrait) {
    .codesamples {
        @apply z-60;
    }
}

@screen lg {
    .codesamples-w {
        width: var(--codeSampleWidthLg);
    }

    .codesamples-pr {
        padding-right: var(--codeSampleWidthLg);
    }
}

@screen xl {
    .codesamples-w {
        width: var(--codeSampleWidthXl);
    }

    .codesamples-pr {
        padding-right: var(--codeSampleWidthXl);
    }
}

.codesamples_trigger {
    @apply fixed;
}

.codesamples--open {
    & .codesamples {
        @apply translate-x-0 opacity-100 pointer-events-auto;
    }

    & .codesamples__backpage {
        @apply opacity-75 pointer-events-auto;
    }
}

.docs-content {
    & h1 {
        font-size: 1.875rem;
        font-weight: 600;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & h3 {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & p {
        font-size: 1rem;
        margin-top: 1.3rem;
    }

    & a {
        &:link,
        &:visited {
            color: theme('colors.orange.500');
        }

        &:hover,
        &:active {
            color: theme('colors.orange.600');
        }
    }

    & ul,
    & ol {
        padding-left: 1rem;
        margin-top: .5rem;
        margin-bottom: 1rem;
        color: theme('colors.gray.700');
    }

    & ul {
        list-style-type: disc;
    }

    & ol {
        list-style-type: decimal;
    }

    & li {
        font-size: 1rem;
        margin-bottom: .25rem;
    }

    & code {
        font-size: .75rem;
        background-color: theme('colors.gray.100');
        border-radius: 3px;
    }

    & pre code {
        font-size: .875rem;
        padding: .75rem 1rem;
        margin: 1rem 0 1rem 0;
        background-color: theme('colors.codesample');
        color: theme('colors.gray.100');
    }

    & p code,
    & table code {
        border: 1px solid theme('colors.gray.200');
        padding: .1rem .3rem;
        color: theme('colors.red.700');
    }

    & .doc__image {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.docs-content table {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;

    & th {
        padding: .75rem 1rem .75rem 0;
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        border-bottom: 1px solid;
        border-color: theme('colors.gray.400');
        color: theme('colors.gray.900');
    }

    &  td {
        padding: .75rem 1rem .75rem 0;
        text-align: left;
        font-size: .875rem;
        border-bottom: 1px solid;
        border-color: theme('colors.gray.400');
        vertical-align: top;
        color: theme('colors.gray.700');

        & ul,
        & ol {
            list-style-type: none;
            padding: 0;
            color: theme('colors.gray.700');
        }

        & ul {
            margin-top: .875rem;
            margin-left: .9375rem;

            & li {
                font-size: .875rem;

                & ul {
                    margin-left: .875rem;
                }
            }
        }
    }
}

.docs-content .callout {
	padding: 1rem;
	border-radius: 0 5px 5px 0;
	display: flex;
	flex-direction: column;
	margin: 1rem 0;
    border-left: 5px solid;
    background-color: theme('colors.codesample');
    color: theme('colors.gray.100');
    overflow: scroll;

    & .title {
        font-weight: 500;
        margin-bottom: .5rem;
    }

    &.info {
        border-color: theme('colors.blue.500');
		& > .title {
			color: theme('colors.blue.500');
		}
	}

	&.success {
        border-color: theme('colors.green.500');
		& > .title {
			color: theme('colors.green.500');
		}
	}

	&.warning {
		border-color: theme('colors.orange.500');
		& > .title {
			color: theme('colors.orange.500');
		}
	}

	&.danger {
		border-color: theme('colors.red.500');
		& > .title {
			color: theme('colors.red.500');
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: theme("colors.gray.900");
}

p {
    color: theme("colors.gray.700");
}
</style>
