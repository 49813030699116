import gsap from 'gsap';

export default {
    methods: {
        slideFadeBeforeEnter(el) {
            /* eslint-disable no-param-reassign */
            el.style.opacity = '0';
            el.style.transform = 'translateX(-0.2rem)';
            /* eslint-enable no-param-reassign */
        },

        slideFadeEnter(el, done) {
            gsap.to(el, {
                duration: 0.4,
                translateX: 0,
                opacity: 1,
                onComplete: done,
            });
        },

        slideFadeLeave(el, done) {
            gsap.to(el, {
                duration: 0.4,
                translateX: '0.2rem',
                opacity: 0,
                onComplete: done,
            });
        },
    },
};
