<template>
    <div class="px-6">
        <ContentLoader
            width="435"
            height="190"
            :speed="2"
            primaryColor="#f7fafc"
            secondaryColor="#edf2f7">
            <rect width="135" height="15" rx="8" />
            <rect y="28" width="435" height="12" rx="5" />
            <rect y="48" width="393" height="12" rx="5" />
            <rect y="68" width="289" height="12" rx="5" />
            <rect y="110" width="135" height="15" rx="8" />
            <rect y="138" width="435" height="12" rx="5" />
            <rect y="158" width="393" height="12" rx="5" />
            <rect y="178" width="289" height="12" rx="5" />
        </ContentLoader>
    </div>
</template>

<script>
import {
    ContentLoader,
} from 'vue-content-loader';

export default {
    name: 'TutorialsLoader',

    components: {
        ContentLoader,
    },
};
</script>
