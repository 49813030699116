var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'codesamples-pr': _vm.activeDocHasCode && !_vm.isLoadingDoc,
        'codesamples--open': _vm.codeSamplesOpen,
    }},[_c('div',{staticClass:"flex flex-row justify-center"},[_c('div',{staticClass:"docs-content w-full py-10 px-6",class:{
                'lg:w-2/3': !(_vm.activeDocHasCode && !_vm.isLoadingDoc),
                'md:w-full': _vm.activeDocHasCode && !_vm.isLoadingDoc,
            }},[(_vm.isLoadingDoc)?_c('DocsLoader'):_vm._e(),_c('div',{ref:'docsContent'},[(!_vm.isLoadingDoc)?_c('VueMarkdown',{attrs:{"source":_vm.activeDoc.content,"anchorAttributes":{target: '_blank'},"linkify":false,"prerender":_vm.parseContent},on:{"rendered":_vm.onMarkdownRendered}}):_vm._e()],1),(!_vm.isLoadingDoc)?_c('TutorialExplore',{staticClass:"mt-16",attrs:{"product":this.activedocProduct,"hasCode":this.activeDocHasCode}}):_vm._e()],1)]),(_vm.activeDocHasCode && !_vm.isLoadingDoc)?_c('span',{staticClass:"codesamples_trigger bg-codesample shadow-md py-4 px-2 top-24 right-0 md:hidden rounded-l-md flex items-center content-center",on:{"click":_vm.openCodeSamples}},[_c('codeSvg',{staticClass:"h-4 fill-current text-gray-100"})],1):_vm._e(),(_vm.activeDocHasCode && !_vm.isLoadingDoc)?_c('div',{ref:"codeSamples",staticClass:"fixed codesamples codesamples-w bottom-0 right-0 top-0 md:top-16 lg:z-60 bg-codesample shadow-2xl md:shadow-none pointer-events-none md:pointer-events-auto opacity-0 md:opacity-100 transform translate-x-full md:translate-x-0"},[_c('DocsCodeSamples',{attrs:{"codeSamples":_vm.activeDoc ? _vm.activeDoc.codeSamples || [] : [],"responseSamples":_vm.activeDoc ? _vm.activeDoc.responseSamples || [] : []}})],1):_vm._e(),_c('div',{ref:"codeSamplesBackpage",staticClass:"block md:hidden codesamples__backpage opacity-0 fixed bg-white pointer-events-none top-0 bottom-0 w-screen z-50",on:{"click":_vm.closeCodeSamples}})])
}
var staticRenderFns = []

export { render, staticRenderFns }